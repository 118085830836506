<template>
  <div class="homepage">
    <div class="banner">
      <svg-icon icon-class="logo_关于艾佳德" width="25%" height="25%" />
      <p class="above">关于艾佳德</p>
      <div class="desc">
        <div class="one">
          北京艾佳德科技发展有限公司成立于2013年11月26日，公司成立至今，专注于心理学教育培训工作，组织开展心理学基础培训，继续教育培训，督导与职业伦理培训等。
        </div>
        <div class="two">
          本公司为中国心理卫生协会心理咨询项目的承办单位，我们将凝聚更多心理学专业学者、专家，陪伴合作机构共同传播心理健康知识。
        </div>
      </div>
    </div>
    <div class="course">
      <div
        class="item"
        v-for="item in courseList"
        :key="item.index"
        @click="courseClick(item)"
        :style="{ backgroundImage: `url(${item.bgImg})` }"
      >
        <div class="item-title">
          <span>{{ item.title }}</span>
          <svg-icon icon-class="butten_right" width="18px" height="13px" />
        </div>
      </div>
    </div>
    <div class="teachers">
      <div class="publicTitle">
        <p class="zh">师资介绍</p>
        <!-- <p class="en">TEACHERS</p> -->
      </div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in teacherList"
            :key="index"
          >
            <div
              class="left"
              :style="{ backgroundImage: `url(${item.pic})` }"
            ></div>
            <div class="right">
              <div class="name_title">
                <div class="name-zh">
                  {{ item.name_zh }}
                </div>
                <!-- <div class="name-cn">
                  {{ item.name_cn }}
                </div> -->
                <div class="line"></div>
              </div>
              <div
                class="info-title"
                v-for="title in item.titles"
                :key="title"
                v-html="title"
              ></div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="notice">
      <div class="publicTitle">
        <p class="zh">通知公告</p>
        <!-- <p class="en">NOTICE NOTICE</p> -->
      </div>
      <div class="content">
        <div
          v-for="item in noticeList"
          :key="item.index"
          @click="checkNotice(item)"
          class="notice-item"
          :class="
            noticeList.indexOf(item) === noticeList.length - 1
              ? ''
              : 'underline'
          "
        >
          <span>{{ item.name }}</span>
          <span>{{ item.date }}</span>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="notice_dialog.visible"
      :title="notice_dialog.title"
      width="90%"
    >
      <div class="img-box-notice">
        <el-image
          :src="`${notice_dialog.pic}`"
          :preview-src-list="[`${notice_dialog.pic}`]"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Swiper from "swiper";
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import { noticeList, teacherList, courseList } from "./data";
import axios from "axios";
export default {
  data() {
    return {
      noticeList: noticeList,
      teacherList: teacherList,
      courseList: courseList,
      notice_dialog: {
        visible: false,
        title: "",
        pic: "",
      },
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      //direction: 'vertical', // 垂直切换选项
      //mousewheel: true, //滚轮
      autoplay: {
        delay: 5000,
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true, // 分页器可以点击
      },
    });
    // this.getInfo();
  },
  methods: {
    getInfo() {
      axios
        .get("/admin/system/official_website/", {
          params: {
            name: this.query,
          },
        })
        .then((response) => {
          const res = response.data;
          if (res.code === 200) {
            this.queryList = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    checkNotice(item) {
      this.notice_dialog.title = item.name;
      this.notice_dialog.pic = item.pic;
      this.notice_dialog.visible = true;
    },
    courseClick(item) {
      if (item.path) {
        this.$router.push({
          path: item.path,
        });
      } else {
        window.open(item.url);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$vw_base: 390;
// $vh_base: 1080;
@function vw($px) {
  //这个是移动端适配用到的
  @return ($px / $vw_base) * 100vw;
}
.homepage {
  .banner {
    height: 400px;
    background: url("~@/assets/img/mb_img/bg_关于艾佳德.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px 24px;
    color: #fff;
    .above {
      font-size: 24px;
      font-weight: 600;
    }
    .desc {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: rgba(255, 255, 255, 0.7);
      .one {
        margin-bottom: 20px;
      }
    }
  }
  .course {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // height: 390px;
    padding: 30px 24px;
    background-color: #fff;
    .item + .item {
      margin-top: 10px;
    }
    .item {
      width: 100%;
      height: 98px;
      display: flex;
      align-items: flex-end;
      .item-title {
        height: 40px;
        width: 100%;
        background-image: linear-gradient(
          to right,
          rgba(168, 126, 100, 1),
          rgba(53, 67, 105, 1)
        );
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .teachers {
    // height: 325px;
    padding: 15px 24px;
    background-color: #f5f5f5;
    .swiper-container {
      height: 250px;
      width: 100%;
      .swiper-slide + .swiper-slide {
        // padding-left: 5px;
      }
      .swiper-slide {
        height: vw(200);
        display: flex;

        .left {
          flex-basis: 44%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }

        .right {
          flex: 1;
          background: rgba(185, 136, 98, 1);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #fff;
          padding: 0 vw(5);
          .name_title {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: vw(5);
            .name-zh {
              font-size: vw(18);
            }
            .name-cn {
              font-size: vw(15);
              margin-top: 4px;
            }
            .line {
              width: 30px;
              height: 1px;
              background: #fff;
              margin: 5px 0;
            }
          }
          .info-title {
            font-size: vw(12);
            line-height: vw(18);
            text-align: center;
            color: #fff;
          }
        }
      }
    }
  }
  .notice {
    padding: 15px 24px;
    .content {
      background-color: #f5f5f5;
      padding: 30px 15px;
      .notice-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
      }
      .underline {
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
  .publicTitle {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #282828;
    margin-bottom: 15px;
    p {
      margin: 0;
      padding: 0;
    }
    .zh {
      font-size: 24px;
      font-weight: 600;
    }
    .en {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .img-box-notice {
    text-align: center;
    height: 450px;
    overflow-y: scroll;

    img {
      width: 542px;
      height: auto;
    }
  }
}
</style>
